module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg",
  },
  promo: {
    title: "Web Design and SEO Company in Seattle",
    description:
      "We will design and build your website using React.js, WordPress, or Shopify, optimize landing pages, and push live hosting on Firebase. You will get high-quality organic traffic ready to convert.",
    items: [
      {
        title: "Set Goals",
        description:
          "Why do you need a website: drive awareness, generate leads, or sell products online?",
        icon: "ti-flag-alt text-white",
      },
      {
        title: "Define Customers",
        description:
          "Define your target audience and reach them in the right place at the right time",
        icon: "ti-thumb-up text-white",
      },
      {
        title: "Convert",
        description:
          "Turn prospects into customers with a long-term, forward-looking game plan",
        icon: "ti-bar-chart text-white",
      },
    ],
  },
  aboutUs: {
    title: {
      bold: "Use your android or ios device",
      normal: "",
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description: "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white",
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white",
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white",
      },
    ],
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$999",
        license: '"Just Started"',
        isFeatured: false,
        features: [
          "Website Design and Build",
          "Google Analytics Set-Up",
          "Basic SEO Optimization",
          "Email Funnel Set-Up",
          "Phone Support",
        ],
      },
      {
        price: "TBD",
        license: "Boost Your Presence",
        isFeatured: true,
        features: [
          "Google Ads",
          "Social Paid Ads",
          "Email Marketing",
          "Content Marketing",
          "Landing Pages Optimization",
        ],
      },
      {
        price: "$60/Mo*",
        license: "Basic Hosting",
        isFeatured: false,
        features: [
          "Firebase Hosting",
          "SSL Certificate",
          "99% Uptime",
          "Email Support",
          "*Price may vary based on the web traffic",
                  ],
      },
    ],
  },
  testimonial: {
    title: "What customer say about us",
    description:
      "We are very fortunate to have formed excellent partnerships and real friendships with our clients. See what our clients say about their experiences with us.",
    comments: [
      {
        name: "Nurlan Abougaliev",
        company: "Allegro Ballet Academy",
        image: "img/client-2.jpg",
        comment:
          "Yulia has done a phenomenal job using photography, color, and balance to re-create our website layout. She always comes up with creative solutions and performs above and beyond expectations. I would highly recommend her to anyone looking to improve their website conversions.",
      },
      {
        name: "Cathy Chis",
        company: "PCTI",
        image: "img/client-1.jpg",
        comment:
          "I have been surprised at the level of excellent contributions Yulia has made to the team and our business based on research she’s done in various areas, especially given her short time with us. She thinks outside of the box and is also very creative artistically. Overall, she has done a great job contributing to the team and is definitely a team player.",
      },
      {
        name: "Anton Putra",
        company: "ExplorOFF",
        image: "img/client-3.jpg",
        comment:
          "Yulia did an outstanding job designing our app and web applications, and with social media marketing. We got over 6k followers through different channels in about half a year. Impressive!",
      },
      {
        name: "Hennadige Thenuwara",
        company: "Team T Resorts",
        image: "img/client-4.jpg",
        comment:
          "I needed a website build for my Colombo Villas resort. Yulia has developed a beautiful high functional website in a very short time. She also helped me with the domain, hosting and social media sites. I would definitely recommend her to a small business looking to grow their online presence.",
      },
      {
        name: "Yulya Fast",
        company: "Fast Way USA",
        image: "img/client-5.jpg",
        comment:
          "I wanna recommend Yuliya Vasileva as an excellent developer and marketing specialist. I was looking for somebody creative and affordable to build my website. I was recommended to use Wasilco, so I connected with Yulia over the phone and explained what I want. I was surprised by how sharp she is. Yulia added a lot of ideas, creativity, and gave me quite a few useful marketing tips. She came up with a clear, user-friendly website to promote my services. Also, she finished it pretty fast, which shows how serious she is about her customers. I highly recommend Yuliya as an experienced and knowledgeable young talent.",
      },
    ],
  },
  contact: {
    title: "Contact with us",
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",

    office: "Head Office",
    address: "121 King St, Melbourne VIC 3000, Australia",
    phone: "+61 2 8376 6284",
    email: "hello@yourdomain.com",
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png",
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png",
  ],
  trustedCompanyAboutUs: {
    title: "Trusted by companies",
    // description:
    //   "We are very fortunate to have formed excellent partnerships and real friendships with our clients. See what our clients say about their experiences with us.",
    companies: [
    {
      image: "img/clients/exploroff.png",
      link: "https://exploroff.com",
      },
      {
      image: "img/clients/ech-appliance-repair.png",
      link: "https://echappliancerepair.com/",
      },
    {
      image: "img/clients/source-contracting.png",
      link: "https://source-contracting-llc.web.app/",
      },
    {
      image: "img/clients/lep.png",
      link: "https://lep-llc.web.app/",
      },
        {
      image: "img/clients/hot-wire-appliance-repair.png",
      link: "https://hotwirerepair.com/",
      },
      {
      image: "img/clients/just-for-you-floral.png",
      link: "https://www.justforyoufloral.com/",
      }, 
      {
      image: "img/clients/retrofit.png",
      link: "https://affordableinteriorsllc-demo.web.app/",
      },
      {
      image: "img/clients/fast-way-usa.png",
      link: "http://www.fastwayusa.com/",
      },
      {
      image: "img/clients/aec.png",
      link: "https://www.aecenterprises.com/",
      },
      {
      image: "img/clients/allegro-ballet-academy.png",
      link: "http://allegroballetacademy.com/",
      },
      {
      image: "img/clients/adults-saving-kids.png",
      link: "https://adultssavingkids.org/",
      },
      {
      image: "img/clients/disama.png",
      link: "https://shopdisama.com//",
      },
      {
      image: "img/clients/raincity.png",
      link: "https://raincity-installationpros.com/",
      },
      {
      image: "img/clients/archos-personal-training.png",
      },
      {
      image: "img/clients/colombo-villas.png",
      },
      {
      image: "img/clients/ms-ballet-academy.png",
      },
      {
      image: "img/clients/pcti.png",
      },
    ],
  },
  teamMember: {
    members: [
      {
        name: "Yulia Vasilyeva, MBA",
        designation: "Founder",
        image: "img/team-4.jpg",
      },
    ],
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA",
  },
};
