import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="header">
          <nav
            className={
              "navbar navbar-expand-lg fixed-top " +
              (this.props.bgColor && this.props.bgColor === "white"
                ? "custom-nav white-bg"
                : "bg-transparent")
            }
          >
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src={
                    this.props.bgColor && this.props.bgColor === "white"
                      ? "img/logo-color-1x.png"
                      : "img/wasilco.svg"
                  }
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  {/* <li className="nav-item dropdown">
                    <a
                      className="nav-link page-scroll dropdown-toggle"
                      href="/#"
                      id="navbarDropdownHome"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Home
                    </a>
                    <div
                      className="dropdown-menu submenu"
                      aria-labelledby="navbarDropdownHome"
                    >
                      <a className="dropdown-item" href="/theme1">
                        Demo Template 1
                      </a>
                      <a className="dropdown-item" href="/theme2">
                        Demo Template 2
                      </a>
                      <a className="dropdown-item" href="/theme3">
                        Demo Template 3
                      </a>
                      <a className="dropdown-item" href="/theme4">
                        Demo Template 4
                      </a>
                      <a className="dropdown-item" href="/theme5">
                        Demo Template 5
                      </a>
                      <a className="dropdown-item" href="/theme6">
                        Demo Template 6
                      </a>
                    </div>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#features">
                      Features
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link page-scroll" href="#pricing">
                      Pricing
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#screenshots">
                      Showcase
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href="#team">
                      Team
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link page-scroll"
                      href="tel:7247995786"
                      target="_newtab"
                    >
                      Call Now
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}))(Header);
