import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="features"
          className="imageblock-section switchable switchable-content"
        >
          <div className="imageblock-section-img col-lg-5 col-md-4">
            {/* LAPTAPPP */}
            <div className="circles">
              <div className="point animated-point-1"></div>
              <div className="point animated-point-2"></div>
              <div className="point animated-point-3"></div>
              <div className="point animated-point-4"></div>
              <div className="point animated-point-5"></div>
              <div className="point animated-point-6"></div>
              <div className="point animated-point-7"></div>
              <div className="point animated-point-8"></div>
              <div className="point animated-point-9"></div>
            </div>
            <div className="col-md-8 col-lg-8">
              <div className="hero-animation-img">
                <img
                  className="img-fluid d-block animation-one"
                  src="img/hero-animation-04.svg"
                  alt="animation"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-two"
                  src="img/hero-animation-01.svg"
                  alt="animation"
                  width="120"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-three"
                  src="img/hero-animation-02.svg"
                  alt="animation"
                  width="120"
                />
                <img
                  className="img-fluid d-none d-lg-block animation-four"
                  src="img/hero-animation-03.svg"
                  alt="animation"
                  width="230"
                />
              </div>
              {/* LAPTAPPP */}
            </div>
            {/* <div
              className="background-image-holder"
              style={{
                backgroundImage: "url('img/about-img.jpg')",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                opacity: 1,
              }}
            >
              {" "}
            </div> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <div className="row align-items-center ptb-100">
                  <div className="col-lg-12">
                    <div className="d-flex align-items-start mb-5">
                      <div className="pr-4">
                        <div className="icon icon-shape icon-color-1 rounded-circle">
                          <span className="ti-face-smile"></span>
                        </div>
                      </div>
                      <div className="icon-text">
                        <h5>Responsive Web Design</h5>
                        <p className="mb-0">
                          Design goal-specific landing pages that appeal to your target market. Remember, you only have 5 seconds to convince your website visitors to stay.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start mb-5">
                      <div className="pr-4">
                        <div className="icon icon-shape icon-color-2 rounded-circle">
                          <span className="ti-vector"></span>
                        </div>
                      </div>
                      <div className="icon-text">
                        <h5>Social Media Engagement</h5>
                        <p className="mb-0">
                          Social media has created a new style of communication, don't miss billions of conversations happening online. Interact with your customers on platforms where they hang out.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start">
                      <div className="pr-4">
                        <div className="icon icon-shape icon-color-3 rounded-circle">
                          <span className="ti-headphone-alt"></span>
                        </div>
                      </div>
                      <div className="icon-text">
                        <h5>Integrated Email Marketing</h5>
                        <p className="mb-0">
                          Don't waste your time emailing offers to purchased leads. Acquire new subscribers, wow them with compelling content, and generate revenue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
