import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/delivery-app.svg"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <span className="badge badge-primary badge-pill">
                  Wasilco Services
                </span>
                <h2 className="mt-4">
                  Prioritize and Invest in the Right Channels
                </h2>
                <p className="mb-4 lh-190">
                  Identify high-value channels and allocate your marketing budget effectively. Marketing is not a one-size-fits-all activity. Let's achieve the best results together!
                </p>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Web Design & Development</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">SEO & Paid Ads</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Social Media</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Video Marketing</p>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <div className="badge badge-circle badge-primary mr-3">
                          <span className="ti-check"></span>
                        </div>
                      </div>
                      <div>
                        <p className="mb-0">Email Marketing</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
