import React from "react";
import { connect } from "react-redux";
import { getQuote } from "../../actions/index";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url =
  "https://wasilco.us5.list-manage.com/subscribe/post?u=5f2668ce1e099d1d2afbc91dd&amp;id=f4c4172cde";

const CustomForm = ({ status, message, onValidated }) => {
  let email, firstName, lastName;
  const submit = () =>
    email &&
    firstName &&
    lastName &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      FNAME: firstName.value,
      LNAME: lastName.value,
    });

  return (
    <div
      style={{
        background: "#efefef",
        borderRadius: 2,
        padding: 10,
        display: "block",
      }}
    >
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        style={{ padding: 15 }}
        ref={(node) => (firstName = node)}
        className="hero-content-left form-control form-group input-group"
        type="text"
        placeholder="First Name"
      />
      <input
        style={{ padding: 15 }}
        ref={(node) => (lastName = node)}
        className="hero-content-left form-control form-group input-group"
        type="text"
        placeholder="Last Name"
      />
      <input
        style={{ padding: 15 }}
        ref={(node) => (email = node)}
        className="hero-content-left form-control form-group input-group"
        type="email"
        placeholder="Email"
      />
      <br />
      <button className="btn solid-btn btn-block" onClick={submit}>
        Free Consultation
      </button>
    </div>
  );
};

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      trustedCompany: [],
      video: "",
      name: "",
      email: "",
      subject: "",
      message: "",
      isAgreed: false,
      disableBtn: false,
      btnText: "Get a Free Consultation",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // // get action
    const quoteAction = getQuote(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(quoteAction);

    // // added delay to change button text to previous
    setTimeout(
      function () {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Get a Free Consultation");

        // get action again to update state
        const quoteAction = getQuote(this.state);

        // Dispatch the contact from data
        this.props.dispatch(quoteAction);

        // clear form data
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
          isAgreed: false,
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section background-img pt-100"
          style={{
            backgroundImage: "url('img/hero-bg-4.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                  <h1 className="text-white">
                    <span>Grow Your Small Business</span>
                  </h1>
                  <p className="lead">
                    Looking for a website that generates tons of traffic and converts visitors into paid customers? Schedule a FREE consultation now.
                  </p>

                  {/* <div className="video-promo-content mt-4 d-flex align-items-center">
                    <a
                      href="https://www.youtube.com/watch?v=9No-FiEInLA"
                      className="popup-youtube video-play-icon"
                    >
                      <span className="ti-control-play"></span>{" "}
                    </a>
                    <span className="ml-4">Watch video overview</span>
                  </div> */}
                </div>
              </div>

              <div className="col-md-5 col-lg-5">
                <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-5">
                  <div className="sign-up-form-header text-center mb-4">
                    <h4 className="mb-0">Schedule a FREE Consultation</h4>
                    <p>fill out the form below</p>
                  </div>
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <CustomForm
                        status={status}
                        message={message}
                        className="sign-up-form"
                        onValidated={(formData) => {
                          console.log("formData:", formData);
                          subscribe(formData);
                        }}
                      />
                    )}
                  />
                  {/* <form
                    method="post"
                    className="sign-up-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group input-group">
                      <input
                        value={this.state.name}
                        onChange={(e) => this.handleFormValueChange("name", e)}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your name"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.email}
                        onChange={(e) => this.handleFormValueChange("email", e)}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    
                    <div className="form-group input-group">
                      <textarea
                        onChange={(e) =>
                          this.handleFormValueChange("message", e)
                        }
                        value={this.state.message}
                        name="message"
                        id="msg"
                        className="form-control"
                        placeholder="Tell us your story"
                        cols="30"
                        rows="4"
                        required
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        onClick={() => {
                          this.changeBtnText("Sending...");
                        }}
                        disabled={this.state.disableBtn}
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn solid-btn btn-block"
                        value={this.state.btnText}
                      />
                    </div>
                    <div className="form-check d-flex align-items-center text-center">
                      <input
                        checked={this.state.isAgreed}
                        onChange={(e) =>
                          this.handleFormValueChange("isAgreed", e)
                        }
                        type="checkbox"
                        name="isAgreed"
                        className="form-check-input mt-0 mr-3"
                        id="ckbAgree"
                      />
                      <label className="form-check-label" htmlFor="ckbAgree">
                        I agree your <a href="/#">terms & conditions</a>
                      </label>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
          <div className="section-shape position-absolute">
            <img src="img/wave-shap.svg" alt="shape" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(HeroSection);
