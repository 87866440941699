import React from "react";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
  const date = new Date();
  const year = date.getFullYear();
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={
              "footer-top background-img-2 " +
              (this.props.noSubscription ? "py-5" : "pt-150 pb-5")
            }
            style={{
              backgroundImage: "url('img/footer-bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="img/wasilco.svg"
                      alt="footer logo"
                      width="120"
                      className="img-fluid mb-3"
                    />
                    <p>
                      Marketing is not a one-size-fits-all activity, let’s
                      achieve the best results together!
                    </p>

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a
                            href="https://www.facebook.com/yulia.vasilyeva.10/"
                            target="_blank"
                            title="Facebook"
                          >
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.linkedin.com/in/yulia-vasilyeva/"
                            target="_blank"
                            title="LinkedIn"
                          >
                            <span className="ti-linkedin"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.instagram.com/vasekkkkk/"
                            target="_blank"
                            title="Instagram"
                          >
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://twitter.com/YuliaVasya"
                            target="_blank"
                            title="Twitter"
                          >
                            <span className="ti-twitter"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="https://www.youtube.com/channel/UCvQRYlDmwB5M2RVOYEnRJMg"
                            target="_blank"
                            title="YouTube"
                          >
                            <span className="ti-youtube"></span>
                          </a>
                        </li>
                        {/* <li className="list-inline-item">
                          <a href="/#" target="_blank" title="Instagram">
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/#" target="_blank" title="printerst">
                            <span className="ti-pinterest"></span>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Others Links</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a href="/#">About Us</a>
                      </li>
                      <li className="mb-2">
                        <a href="/#">Contact Us</a>
                      </li>
                      <li className="mb-2">
                        <a href="/#">Pricing</a>
                      </li>
                      <li className="mb-2">
                        <a href="/#">Privacy Policy</a>
                      </li>
                      <li className="mb-2">
                        <a href="/#">Terms and Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Support</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        Seattle, WA
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        <a href="tel:+61283766284"> (724)799-5786</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:yuliavasilyeva1993@gmail.com"> yuliavasilyeva1993@gmail.com</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/#"> wasilco.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-nav-wrap text-white">
                      <img
                        link="https://www.expertise.com/wa/federal-way/digital-marketing-agencies"
                        width="250"
                        height="200"
                        src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/wa_federal-way_digital-marketing-agencies_2022_inverse.svg"
                      alt="Top Digital Marketing Agency in Federal Way" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    Copyrights© {year}. All rights reserved by Wasilco.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
